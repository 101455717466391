import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import { Box } from '@rebass/grid';

import CategoryHero from 'components/heros/categoryHero';
import GridRow from 'components/grid/gridRow';
import GridItem from 'components/grid/gridItem';
import Heading from 'components/typography/heading';
import BasicTeaser from 'components/teasers/basicTeaser';
import SignUpCta from 'components/ctas/signUpCta';

const GreyContainer = styled(Box)`
  background-color: ${p => p.theme.greyBg};
`;

const Features = ({ data }) => {
  const {
    markdownRemark: { frontmatter: {
      hero,
      otherFeatures,
    } },
    features,
  } = data;

  const otherFeaturesArray = Object.keys(otherFeatures).map(i => otherFeatures[i]);

  return (
    <React.Fragment>
      <CategoryHero
        title={hero.title}
        subtitle={hero.subtitle}
        image={hero.image}
      />

      <GridRow
        title={<Heading rank="span" style="h2">Key features</Heading>}
        gridWrapper="ul"
        gridItems={
          features && features.edges.map((feature) => {
            const { node: { frontmatter: {
              path,
              teaser: {
                title, subtitle, icon, image,
              },
            } } } = feature;

            return (
              <GridItem key={path} as="li" width={[1, 1 / 2]}>
                <BasicTeaser
                  title={title}
                  subtitle={subtitle}
                  image={image}
                  icon={icon}
                  linkTo={path}
                />
              </GridItem>
            );
          })
        }
      />

      <GreyContainer>
        <GridRow
          title={<Heading rank="span" style="h2">Plus loads more</Heading>}
          gridWrapper="ul"
          gridItems={
            otherFeaturesArray && otherFeaturesArray.map((otherFeature) => {
              const { title, subtitle, icon } = otherFeature;

              return (
                <GridItem key={title} as="li">
                  <BasicTeaser
                    title={title}
                    subtitle={subtitle}
                    icon={icon}
                  />
                </GridItem>
              );
            })
          }
        />
      </GreyContainer>

      <SignUpCta />
    </React.Fragment>
  );
};

export const query = graphql`
  query($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      ...LayoutFragment
      frontmatter {
        otherFeatures {
          one {
            title
            subtitle
            icon
          }
          two {
            title
            subtitle
            icon
          }
          three {
            title
            subtitle
            icon
          }
          four {
            title
            subtitle
            icon
          }
        }
      }
      ...CategoryHeroFragment
    }
    features: allMarkdownRemark(
      filter: {frontmatter: { type: {eq: "feature"}}},
      sort: {fields: [frontmatter___teaser___order], order: ASC},
    ) {
      edges {
        node {
          ...BasicTeaserFragment
        }
      }
    }
  }
`;

Features.propTypes = {
  data: PropTypes.object.isRequired,
};

export default Features;
